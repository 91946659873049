<template>
  <div v-if="false">
    <div
      v-if="showHelpDesksChatBox"
      :class="`float-chatbox bg-light`"
      :style="
        isMobile
          ? 'bottom: 50px; right: 50px; height: 70vh; width: 80vw'
          : 'bottom: 90px; right: 90px; height: 60vh; width: 22vw'
      "
    >
      <div class="relative-div">
        <b-button
          class="p-0 m-0 close-icon"
          variant="danger"
          @click="closeChat"
        >
          <feather-icon
            icon="XIcon"
            size="20"
          />
        </b-button>
      </div>

      <user-help-desks-issue-form
        v-if="$isEmpty(issue._id)"
        @issue-created="issueCreated"
      />

      <chat-box
        v-else
        :is-widget="true"
        :issue="issue"
        :issue-chats="issueChats"
        :sender="sender"
        :recipient="recipient"
        :current-queue="currentQueue"
        :current-queue-meta="currentQueueMeta"
      />
    </div>

    <b-avatar
    
      v-b-tooltip.hover.left="`Click here to chat with us`"
      class="float-button"
      :style="
        isMobile
          ? 'bottom: 20px; right: 20px; width: 45px; height: 45px;' 
          : 'bottom: 40px; right: 40px; width: 60px; height: 60px;'
      "
      variant="success"
      :badge="showOpenChatButtonBadge"
      badge-right
      badge-top
      badge-variant="danger"
    >
      <div
        class="d-flex align-items-center h-100 w-100"
        @click="openChat"
      >
        <feather-icon
          class="w-100"
          icon="MessageSquareIcon"
          size="35"
        />
      </div>
    </b-avatar>
  </div>
</template>

<script>
import { isUserLoggedIn } from '@/auth/utils'
import ChatBox from '@/views/help-desks/components/ChatBox.vue'
import UserHelpDesksIssueForm from '@/views/help-desks/components/UserHelpDesksIssueForm.vue'
import { onResizeMixins } from './mixins/onResizeMixins'

export default {
  components: {
    ChatBox,
    UserHelpDesksIssueForm,
  },
  mixins: [
    onResizeMixins,
  ],
  data() {
    return {
      showChatBoxButton: false,
      showHelpDesksChatBox: false,

      issue: {},
      issueChats: [],
      previousIssueChatsCount: 0,
      showOpenChatButtonBadge: false,

      sender: {},
      recipient: {},

      refreshChatInterval: null,

      // issue queue
      currentQueue: [],
      currentQueueMeta: {},
      refreshCurrentQueueInterval: null,
      refreshAssignedTo: null,
      hiddenRouteList: [
        'help-desks-index',
        'help-desks-issues-view',
      ],
    }
  },
  watch: {
    '$route.name': function (val) {
      if (this.isLoggedIn() && !this.hiddenRouteList.includes(this.$route.name)) {
        this.showChatBoxButton = true
      } else {
        this.showChatBoxButton = false
      }
    },
  },
  mounted() {
    if (this.isLoggedIn() && !this.hiddenRouteList.includes(this.$route.name)) {
      this.showChatBoxButton = true
    }
  },
  destroyed() {
    clearInterval(this.refreshAssignedTo)
    clearInterval(this.refreshChatInterval)
    clearInterval(this.refreshCurrentQueueInterval)
  },
  methods: {
    isLoggedIn() {
      return isUserLoggedIn()
    },
    get_issue(id) {
      if (!this.$isEmpty(id)) {
        this.$http
          .get(`/api/help-desks/issues/${id}`)
          .then(response => {
            this.issue = response.data.output
          })
          .catch(() => {})
      }
    },
    get_previous_open_issue() {
      const { email } = this.$store.state.auth.ActiveUser
      let query = ''

      query += `${this.$attachQuerySymbol(query)}issue_owner=${email}`
      query += `${this.$attachQuerySymbol(query)}issue_index=first`
      query += `${this.$attachQuerySymbol(query)}exclude_bot=false`

      this.$http
        // .get(`/api/help-desks/issues${query}`)
        .get(`/api/help-desks/issues${query}`)
        .then(response => {
          if (response.data.output.contact_method === 'telegram') {
            //
          } else {
            this.issue = response.data.output

            if (this.issue._id) {
              this.issueCreated(this.issue)
            }
          }
        })
        .catch(() => {})
    },
    get_chats() {
      if (!this.$isEmpty(this.issue._id)) {
        this.$http
          .get(`/api/help-desks/issues/${this.issue._id}/chats`)
          .then(response => {
            this.issue.chats = response.data.output
            this.issueChats = response.data.output
            if (this.previousIssueChatsCount !== this.issueChats.length) {
              this.showOpenChatButtonBadge = true
              this.get_issue(this.issue._id)
            }
            this.previousIssueChatsCount = this.issueChats.length
          })
          .catch(() => {})
      }
    },
    get_sender() {
      this.sender = this.$store.state.auth.ActiveUser
    },
    get_recipient() {
      const currentUser = this.$store.state.auth.ActiveUser.email
      this.recipient.email_address = this.issue.user_id
      if (currentUser === this.issue.user_id && !this.$isEmpty(this.issue.assigned_to)) {
        // ! currently defaulting to the sender
        this.recipient.email_address = this.issue.assigned_to
      }

      this.$http
        .get(`/api/users/${this.recipient.email}`)
        .then(response => {
          this.recipient = response.data
        })
        .catch(() => {
          this.$toast(
            {
              component: this.$toastContent,
              props: {
                title: 'Something went wrong',
                icon: 'AlertCircleIcon',
                text: 'Please try again later',
                variant: 'danger',
              },
            },
            { timeout: this.$longestTimeout },
          )
        })
    },
    get_current_issue_queue_status() {
      if (!this.$isEmpty(this.issue._id)) {
        this.$http
          .get(`/api/help-desks/issues-queue-current/${this.issue._id}`)
          .then(response => {
            this.currentQueue = response.data.output
            this.currentQueueMeta = response.data.meta
          })
          .catch(() => {})
      }
    },

    openChat() {
      this.showHelpDesksChatBox = !this.showHelpDesksChatBox
      if (this.showHelpDesksChatBox) {
        this.get_previous_open_issue()
      }
      this.showOpenChatButtonBadge = false
    },
    closeChat() {
      if (!this.$isEmpty(this.issue._id)) {
        this.confirmCloseIssue()
      }
      this.showHelpDesksChatBox = false
      this.showOpenChatButtonBadge = false
    },
    confirmCloseIssue() {
      this.$bvModal
        .msgBoxConfirm(
          `Are you sure you want to close "${this.issue.subject}"`,
          {
            title: 'Confirm Closing Issue',
            size: 'sm',
            okVariant: 'danger',
            okTitle: 'Close',
            cancelTitle: 'Cancel',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          },
        )
        .then(value => {
          if (value) {
            this.closeIssue()
          }
        })
    },
    closeIssue() {
      this.$http
        .delete(`/api/help-desks/issues/${this.issue._id}/close`)
        .then(response => {
          this.issue = {}
        })
        .catch(() => {})
    },

    issueCreated(event) {
      clearInterval(this.refreshAssignedTo)
      this.issue = event || {}

      if (!this.$isEmpty(this.issue._id)) {
        this.refreshAssignedTo = setInterval(() => {
          this.get_issue(this.issue._id)
          this.get_current_issue_queue_status()

          if (!this.$isEmpty(this.issue.assigned_to) || !this.issue.unresolvable_by_bot) {
            clearInterval(this.refreshChatInterval)
            // if (this.$isEmpty(this.refreshChatInterval)) {
            this.refreshChatInterval = setInterval(() => {
              this.get_chats()
            }, 2000)
            // }

            this.get_sender()
            this.get_recipient()
          }

          if (!this.$isEmpty(this.issue.assigned_to)) {
            clearInterval(this.refreshAssignedTo)
          }
        }, 3000)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/variables.scss";

.relative-div {
  position: relative;
  z-index: 100;
}
.close-icon {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 5px;
  right: 5px;
  border-radius: 999px;
  text-align: center;
}

.float-chatbox{
  padding: 0;
  position: fixed;
  width: 22vw;
  height: 60vh;
  bottom: 90px;
  right: 90px;
  // background-color:#0C9;
  // background-color: $success;
  // color:#FFF;
  // border: 2px solid $success;
  border-radius: 10px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  z-index: 10;
  overflow: hidden;
}

.float-button{
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color:  $success;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  box-shadow:  2px 2px 3px #999;
  z-index: 10;
  padding: 3px;
}

.my-float-button{
  margin-top: 22px;
}
</style>
