<template>
  <div class="box-shadow-5 h-100 w-100">
    <div
      style="height: 10%;"
      class="p-1"
    >
      <h2 class="w-100 font-weight-bolder">
        Chat Us
      </h2>
      <hr>
    </div>

    <div
      style="height: 80%;"
      class="p-1 text-left"
    >
      <div
        class="py-1"
        style="height: 100%; overflow-y: auto;"
      >
        <div>
          <h5>What is the issue?</h5>
          <div>
            <b-form-textarea
              ref="issueSubjectInput"
              v-model="issue.subject"
              rows="3"
              placeholder=""
              no-resize
              class="w-full m-0 p-0"
            />
          </div>
        </div>

        <div class="mt-1">
          <h5>Upload a screenshot</h5>
          <div>
            <b-button
              class="ml-1 w-64 shadow-md"
              variant="warning"
              @click="$refs.uploadFile.click()"
            >Upload Screenshot</b-button>

            <input
              ref="uploadFile"
              class="hidden"
              type="file"
              accept=".jpg,.jpeg,.png,.svg"
              @change="uploadFile"
            >

            <div class="m-1 text-center">
              <b-img
                style="max-height: 18vh; width:auto;"
                class="img-fluid border border-primary"
                :src="upload_url"
              />
            </div>
          </div>
        </div>

        <!-- <div class="mt-2">
          <h5>Please briefly describe the issue</h5>
          <div>
            <b-form-textarea
              v-model="issue.description"
              rows="3"
              placeholder=""
              no-resize
              class="w-full m-0 p-0"
            />
          </div>
        </div> -->

        <!-- <div class="mt-1">
          <h5>Priority</h5>
          <b-form-row
            class="w-100 mb-1"
          >
            <b-col class="px-0">
              <v-select
                id="role"
                ref="priorityVSelect"
                v-model="issue.priority"
                :placeholder="
                  !$isEmpty(issue.priority)
                    ? issue.priority
                    : 'Pick a communication method'
                "
                class="bg-white selectExample w-full"
                :options="priorityOptions"
                :reduce="priority => priority.value"
                label="text"
              />

              <b-form-invalid-feedback tooltip>
                Please select a priority
              </b-form-invalid-feedback>
            </b-col>
          </b-form-row>
        </div> -->
      </div>
    </div>

    <div
      style="height: 10%;"
      class="px-1 pb-1"
    >
      <b-button
        class="float-right shadow-lg"
        variant="primary"
        @click="startChat"
      >Start Chat</b-button>
    </div>
  </div>
</template>

<script>
import VSelect from 'vue-select'
import { debounce } from 'lodash'

export default {
  components: {
    VSelect,
  },
  data() {
    return {
      issue: {},
      files: [],
      upload_url: '',

      priorityOptions: [
        { text: 'Critical - Servce unusable in productino', value: 'critical' },
        { text: 'High - Servce severely impaired', value: 'high' },
        { text: 'Medium - Servce partially impaired', value: 'medium' },
        { text: 'Low - Servce usable', value: 'low' },
      ],

      allCompaniesOptions: [],
    }
  },
  mounted() {
    this.$refs.issueSubjectInput.focus()
  },
  methods: {
    post_issue() {
      const reader = new FileReader()
      reader.onload = e => {}

      const fd = new FormData()
      fd.append('subject', this.issue.subject)
      fd.append('official_name', this.issue.official_name)
      fd.append('preferred_contact_method', 'chat')
      fd.append('contact_method', 'chat')
      fd.append('user_id', this.$store.state.auth.ActiveUser.email)
      if (!this.$isEmpty(this.files)) {
        fd.append('files', this.files[0])
      }

      this.$http
        .post('/api/help-desks/issues', fd)
        .then(response => {
          if (response.data.success) {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Chat Sent',
                  icon: 'AlertCircleIcon',
                  text: 'Chat successfully sent',
                  variant: 'success',
                },
              },
              { timeout: this.$longestTimeout },
            )

            this.issue = response.data.output
            this.$emit('issue-created', this.issue)
          }
        })
        .catch(() => {
          this.$toast(
            {
              component: this.$toastContent,
              props: {
                title: 'Something went wrong',
                icon: 'AlertCircleIcon',
                text: 'Please try again later',
                variant: 'danger',
              },
            },
            { timeout: this.$longestTimeout },
          )
        })
    },

    getCompanies(query) {
      this.$http
        .get(`/api/company-search/${query}`)
        .then(response => {
          this.allCompaniesOptions = []
          if (response.data.success) {
            response.data.data.forEach(item => {
              this.allCompaniesOptions.push(item.text)
            })
          }
        })
        .catch(() => {})
    },
    handleCompanyNameInput: debounce(function (text) {
      this.allCompaniesOptions = []
      this.getCompanies(text)
    }, 1000),

    uploadFile(input) {
      this.files = input.target.files

      this.files.forEach(file => {
        // console.log(file)
      })

      this.upload_url = URL.createObjectURL(this.files[0])
    },
    startChat() {
      this.post_issue()
    },
  },
}
</script>

<style>

</style>
